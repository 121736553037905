<template>
  <div class="flex justify-center">
   <input v-model="model" type="text" class="page_input w-12 text-center text-[0.875rem] text-[#3C4549] font-poppins leading-none" />
    <button @click="$emit('click', model)" class=" h-8 p-2.5 bg-[#2560D7] rounded-tr rounded-br flex justify-center items-center">
      <span class="font-poppins text-[0.875rem] mr-1 text-white">Go</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
        <path d="M5.86077 5.81373C6.41912 5.41491 6.41912 4.58509 5.86077 4.18627L1.58124 1.12946C0.919369 0.656692 -3.87728e-07 1.12982 -3.52174e-07 1.94319L-8.49395e-08 8.05681C-4.93859e-08 8.87018 0.91937 9.34331 1.58124 8.87054L5.86077 5.81373Z" fill="white"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default ({
  'name': 'TableCustomPage',
  props: ['page'],
  data() {
    return {
      model: 1
    }
  },
  watch: {
    'page' (value) {
      this.model = value
    }
  }
})
</script>
<style lang="less">
.page_input {
  height: 2rem !important;
  padding: 0.625rem !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
  border: 1px solid #E5E7EB !important;
}
</style>
